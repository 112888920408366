import axios2 from "@/utils/request_2.js";

/**
 * 视频教材版本-查询数据
 * @param {"subject_id"|"apply_type"|"is_display"} search_type subject_id:科目 apply_type : 应用年级 is_display:筛选学生是否可见
 * @return {Promise<AxiosResponse<any>>}
 */
export function searchVersion(search_type){
    return axios2.get("/api/video/search-version",{
        params:{
            search_type
        },
        logic:1
    })
}

/**
 * 获取教材版本列表数据
 * @param {{subject_id?:string|number|null,apply_type?:string|number|null,teach_version?:string|number|null,teach_name?:string|number|null,page?:number,get_all?:number|null}} params
 * @return {Promise<AxiosResponse<any>>}
 */
export function getVersionList(params = {}){
    return axios2.get("api/video/list-version",{
        params,
        logic:1
    })
}
