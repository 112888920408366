import { render, staticRenderFns } from "./EditCard.vue?vue&type=template&id=cac38c9a&scoped=true"
import script from "./EditCard.vue?vue&type=script&lang=js"
export * from "./EditCard.vue?vue&type=script&lang=js"
import style0 from "./EditCard.vue?vue&type=style&index=0&id=cac38c9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cac38c9a",
  null
  
)

export default component.exports