<!--
  封装的el-tree
  可编辑的树
-->
<template>
  <el-tree
      :data="data"
      node-key="key"
      ref="tree"
      class="t-editable-tree"
      :props="props"
      default-expand-all
      :expand-on-click-node="false"
      @node-drag-start="emits('node-drag-start',arguments)"
      @node-drag-enter="emits('node-drag-enter',arguments)"
      @node-drag-leave="emits('node-drag-leave',arguments)"
      @node-drag-over="emits('node-drag-over',arguments)"
      @node-drag-end="emits('node-drag-end',arguments)"
      @node-drop="emits('node-drop',arguments)"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag">
    <template slot-scope="{ node, data }">
          <span class="custom-tree-node">
            <div class="item" :title="node.label" v-show="!node.edit" @dblclick="editable(node)?editNode(node):undefined;">
                  <span class="ellipsis tree-label">{{ node.label }}</span>
            </div>
            <input
                class="tree-input"
                v-model="data[props.label]"
                v-show="node.edit"
                :maxlength="maxNameLength"
                :id="'edit-'+node.id"
                placeholder="请输入"
                @keyup.enter="rename(true)"
                @blur="rename(false)"
                @click.stop
                :draggable="true"
                @dragstart.stop
                @dragstart.prevent
            ></input>
            <span>
              <i class="edit-icon el-icon-circle-plus-outline" v-if="creatable(node)" @click.stop="addNode(node,data)"></i>
              <i class="edit-icon el-icon-edit" @click.stop="editNode(node)" v-if="editable(node)"></i>
              <i class="edit-icon el-icon-delete" @click.stop="removeNode(node)" v-if="deletable(node)"></i>
            </span>
          </span>
    </template>
  </el-tree>
</template>

<script>
export default {
  name: "TEditableTree",
  model:{
    prop:"data",
    event:"input"
  },
  props:{
    // 数据
    data:Array,
    // 最大层级
    maxLevel:{
      type:Number,
      default:4
    },
    // 允许label为空
    allowEmpty:{
      type:Boolean,
      default:false
    },
    // 节点默认值
    nodeDefault:String,
    // 编辑节点名时,最大长度
    maxNameLength:{
      type:Number,
      default:30
    },
    // 删除提醒
    removeRemind:{
      type:Boolean,
      default:true
    },
    // 节点属性
    props:{
      type:Object,
      default(){
        return {
          label:"label",
          children:"children",
          disabled:false,
          isLeaf:false
        }
      }
    },
    // 对层级进行单独控制, 每个下标对应一层子树
    levelRule:{
      type:Array,
      default(){
        return [
          {
            deletable:true, // 是否可删除
            editable:false, // 是否可编辑
            onlyDeleteChild:true, // 是否只删除子节点
            creatable:true, // 是否可创建子节点
            maxLength:4,    // 子节点最大长度, 设为-1 表示不限制
          }
        ]
      }
    },
    // 判断节点能否被放置
    allowDrop:Function,
    // 判断节点能否被拖拽
    allowDrag:Function,
  },
  data(){

    return{
      enter:false,
      // 当前节点
      currentNode:null,
      defaultLevelRule:{
        deletable:true, // 是否可删除
        onlyDeleteChild:false, // 是否只删除子节点
        editable:true, // 是否可编辑
        creatable:true, // 是否可创建子节点
        maxLength:-1,    // 子节点最大长度, 设为-1 表示不限制
      }
    }
  },
  mounted() {
    // this.data = this.value;
  },
  methods:{
    // 生成一个node-key
    createKey(){
      return this.$tools.GUID();
    },
    // 获取指定节点的规则
    getRule(node){
      let index = node.level-1;
      let rule = this.levelRule[index] || {};
      let result = {};
      for (let k in rule){
        let item = rule[k];
        if (typeof item === "function"){
          let res = item(node);
          result[k]=res==null?true:res;
        }else result[k]=item;
      }
      return Object.assign({}, this.defaultLevelRule, result);
    },
    /***************************节点操作按钮控制 Start**************************************/
    // 可删除
    deletable(node){
      let rule = this.getRule(node);
      return rule.deletable;
    },
    // 可编辑
    editable(node){
      let rule = this.getRule(node);
      return rule.editable;
    },
    // 可创建
    creatable(node){
      let rule = this.getRule(node);
      let maxLength = rule.maxLength;
      if (maxLength !== -1 && node.childNodes.length>=maxLength)return false;
      return rule.creatable && node.level < this.maxLevel;
    },
    /***************************节点操作按钮控制 End**************************************/
    /*****************************节点控制 Start****************************************/
    // 添加节点
    addNode(node){
      if (node.level >= this.maxLevel)return;
      this.$refs.tree.append({
        [this.props.label]: this.nodeDefault,
        [this.props.children]: [],
        key:this.createKey()
      },node)
      node.expanded = true;
      this.$nextTick(()=>{
        let child = node.childNodes;
        let last = child[child.length-1];
        this.editNode(last)
      })
    },
    // 重命名节点
    rename(isEnter){
      if (isEnter)this.enter = true;
      let node = this.currentNode;
      if (!this.allowEmpty && this.$tools.isEmpty(node.data[this.props.label]) && ((!isEnter && !this.enter)||isEnter))
        return this.$alert("请输入节点名称", "提示", {
        }).then(()=>{
          this.enter = false;
          this.editNode(node);
        })

      this.enter = false;
      this.$set(this.currentNode,"edit",false);
    },
    // 编辑节点
    editNode(node){
      this.currentNode = node;
      this.$set(node,"edit",true);
      this.$nextTick(()=>{
        document.getElementById("edit-"+node.id).focus();
      })
    },
    // 删除节点
    removeNode(node){
      let role = this.getRule(node);
      const remove = ()=>{
        if (role.onlyDeleteChild){
          this.$refs.tree.updateKeyChildren(node.data.key,[]);
        }else{
          this.$refs.tree.remove(node);
        }
      }
      if (this.removeRemind && this.$store.state.treeDeleteRemind && node.childNodes.length){
        const h = this.$createElement;
        let value = false;
        this.$msgbox({
          title:"提示",
          type:"warning",
          showCancelButton:true,
          message:h("div",null,[
            h("p", {
              style:"font-size:14rem;color:#333;"
            },"删除该节点将会连同其下属的所有子节点一同删除，是否确认删除？"),
            /*h("el-checkbox",{
              style:{color:'#999',marginTop:'24rem'},
              props:{
                label:'本次会话不再提醒',
                checked:value
              },
              on:{
                input:(val)=>{
                  value = val;
                }
              }
            })*/

          ]),
          confirmButtonText:"确定",
          cancelButtonText:"取消",
        }).then(()=>{
          this.$store.commit("setTreeDeleteRemind",!value);
          remove();
        })
      }else remove();

    },
    emits(name,args){
      this.$emit(name,...args);
    }
  },
}
</script>

<style lang="scss" scoped>
.t-editable-tree{
  .custom-tree-node{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14rem;
    width:500rem;
    .edit-icon{
      margin-right: 10rem;
      cursor: pointer;
    }
    .tree-label{
      display: inline-block;
      width: 420rem;
      max-width: 420rem;
    }
    .tree-input{
      font-size: 14rem;
      width:420rem;
    }
  }
  ::v-deep.el-tree-node__content,.el-tree-node__expand-icon.is-leaf,
  .is-dragging.is-drop-not-allow .el-tree-node__content{
    cursor: move !important;
  }
}

</style>
