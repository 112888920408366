<template>
  <div class="t-edit-card">
    <slot name="header"></slot>
    <div class="t-edit-card-header" v-if="!$slots.header">
      <div class="t-edit-card-header-title">{{title}}</div>
      <div class="t-edit-card-header-line" v-show="headLine"></div>
    </div>
    <div class="t-edit-card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TEditCard",
  props:{
    // 标题
    title:String,
    // 标题分割线
    headLine:{
      type:Boolean,
      default(){
        return true;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_setting.scss";
.t-edit-card{
  background-color: white;
  padding: 12rem 10rem;
  border-radius: 8rem;
  margin-bottom: 24rem;
  &-header{
    color: $primaryDark;
    font-size: 20rem;
    &-title{
      padding-left: 14rem;
      margin-bottom: 12rem;
    }
    &-line{
      height: 1px;
      width: 100%;
      background-color: $gray;
    }

  }
  &-body{
    padding-top: 24rem;
  }
}
.t-edit-card:last-child{
  margin-bottom: 0;
}
</style>
